<template>
    <div class="top">
        <div class="t-header">
            <div class="title">
                아빠미소멘토단의 생생한 소식을 전해드립니다<br>
                <span>공지사항</span>
            </div>
        </div>
        <div class="filter-wrap">
            <div class="search">
                <el-select v-model="filterInputOption" interface="popover" ok-text="확인" cancel-text="취소" class="select center"
                           @change="parentSetData('filterInputOption', filterInputOption)">
<!--                    <el-option value="all">제목</el-option>-->
                    <el-option :value="'su'" :label="'제목'"></el-option>
                    <el-option :value="`co`" :label="`내용`"></el-option>
                </el-select>
                <div class="input">
                    <input type="text" placeholder="검색.."
                           v-model="filterInput"
                           @input="parentSetData('filterInput', $event.target.value)"
                           @keypress.enter="setFilterFunc(filterInputOption, filterInput)"></input>
                    <button class="search_btn" @click="setFilterFunc(filterInputOption, filterInput)"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import boardFilterUtil from "@/components/board/boardFilterUtil";
    import EventBus from "@/utils/event-bus";
    import util from "@/mixins/util";
    const {filterConfig} = boardFilterUtil;
    export default {
        name: "BoardFilterDefaultLayout",
        inject: ['setPage', 'setData', 'setFilter'],
        props: ['filterData'],
        data() {
            return {
                filterInput: '',
                filterInputOption: 'su',
            }
        },
        computed: {

        },
        created() {
            EventBus.$on('resetFilterData', this.resetFilterData);
            this.resetFilterData();
        },
        mounted() {


        },
        beforeDestroy() {
            EventBus.$off('resetFilterData');
        },
        methods: {
            setterFilterDataInData() {
                let classnames = Object.keys(this.filterData);
                classnames.forEach(classname => {
                    let obj = this.filterData[classname];
                    let type = filterConfig[obj.key].type;
                    if(this.hasOwnProperty(classname)) {
                        // this[classname] =
                        if(type == 'query') {
                            this[classname] = obj.query;
                        }else if(type === 'between') {
                            this[classname] = [obj.start, obj.end];
                        }else if(type === 'match') {
                            this[classname] = obj.value;
                        }
                        if(this.hasOwnProperty(classname+'Option')) {
                            this[classname+'Option'] = obj.key;
                        }

                    }
                })
            },
            parentSetData(key, value) {
                console.log(key,value)
                this[key] = value;
                this.setData(key, value);
            },
            setFilterFunc(key, value) {
                let classname = filterConfig[key].classname;
                if(!classname) {
                    return;
                }
                this[classname] = value;
                this.setData(classname, value);
                this.setFilter(key, value);
            },
            resetFilterData() {
                if(util.isEmpty(this.filterData)) {
                    this.filterInput = '';
                    this.filterInputOption = 'su';
                } else {
                    this.filterInput = this.filterData.filterInput.query;
                    this.filterInputOption = this.filterData.filterInput.key;
                }
            },
        },
        watch: {
        },
    }
</script>

<style scoped>

</style>
